// eslint-disable-next-line no-redeclare,no-unused-vars
function buildStoreInfo(cont) {
  var cont1 = $(
    '<div id="store-cont" class="store-content mb-3"></div>'
  ).appendTo(cont);
  var cont2 = $(
    '<a href="javascript:void(0);" class="link-light text-decoration-none" aria-expanded="false"></a>'
  ).appendTo(cont1);
  var cont3 = $('<div class="avatar avatar-md"></div>').appendTo(cont2);
  var src;
  if (store.avatar && store.avatar.length > 50) src = store.avatar;
  else if (store.logo)
    src = getObjectValue(data, ['document', store.logo, 'file']);
  if (src) $('<img src="' + src + '" class="img-fluid">').appendTo(cont3);
  else
    $(
      '<span class="avatar-title rounded-circle">' +
        (store.acronym ? store.acronym : '') +
        '</span>'
    ).appendTo(cont3);
  var cont4 = $('<div class="store-info flex-column"></div>').appendTo(cont2);

  cont3.val = function (val) {
    cont3.empty();
    $('<img src="' + val + '" class="img-fluid">').appendTo(cont3);
  };
  register(cont3, 'store', store.id, 'avatar');

  if (!store.company) store.company = '';
  if (!store.title) store.title = '';

  if (data.store && Object.keys(data.store).length > 1) {
    multiStore = 1;
    cont1.addClass('dropdown');
    cont2.addClass('dropdown-toggle').attr('data-bs-toggle', 'dropdown');

    dropdowns.store.all = {
      label: 'Alle Filialen',
      store: {title: 'Alle Filialen', id: 0},
      jsFunc: 'switchStore',
      noAjax: true,
    };
    dropdowns.store.list = ['all'];
    $.each(data.store, function (i, row) {
      var ident = 'store' + row.id;
      var point = Object.assign({}, dropdowns.store.store);
      point.store = row;
      point.label = row.short;
      dropdowns.store[ident] = point;
      dropdowns.store.list.push(ident);
      if (row.main) mainStore = row;
    });
    buildDropdown(cont1, dropdowns.store);
    $('<h5>' + store.company + '</h5><small>Alle Filialen</small>').appendTo(
      cont4
    );
  } else
    $(
      '<h5>' + store.title + '</h5><small>' + store.company + '</small>'
    ).appendTo(cont4);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function switchStore(evt, point) {
  store = point.store;
  if (!store.id) store = mainStore;
  storeFilter = point.store.id;

  ajax({
    object: 'cache',
    post: true,
    send: {store: storeFilter},
    callback: function () {
      $.each(elements.tables, function (i, tables) {
        $.each(tables, function (i, table) {
          table.update();
        });
      });
    },
  });

  $(evt.target).parents('ul').prev().find('small').text(store.title);
}
